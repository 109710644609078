<template>
    <div class="container">
        <h1 class="mt-4">Frequently Asked Questions</h1>
        <hr>
        <p class="mb-4">
            Hier findest du häufig gestellte Fragen,
            direkt von Schülern beantwortet.
        </p>
        <div
            class="accordion"
            role="tablist"
        >
            <b-card 
                no-body 
                v-for="(faq, index) in faqData"
                :key="index"
            >
                <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                >
                    <b-button
                        @click="faq.visible = !faq.visible"
                        variant="info"
                        block
                    >
                        {{ faq.question }}
                    </b-button>
                </b-card-header>
                <b-collapse
                    v-model="faq.visible"
                    accordion="faq-accordion"
                    role="tabpanel"
                >
                    <b-card-body>
                        <b-list-group
                            v-if="Array.isArray(faq.answer)"
                            id="faqList"
                        >
                            <b-list-group-item
                                v-for="(text, index) in faq.answer"
                                :key="index"
                                v-html="text"
                            />
                        </b-list-group>
                        <b-card-text
                            v-else
                            v-html="faq.answer"
                        />
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BButton, BCollapse, BListGroup, BListGroupItem, BCardText } from "bootstrap-vue"
import faqData from '@/assets/json/faq.json';
export default {
    name: "Faq",
    components:{ BCard, BCardHeader, BCardBody, BButton, BCollapse, BListGroup, BListGroupItem, BCardText },
    data: () => ({
        faqData
    })
}
</script>

<style scoped>
    code {
        color: grey;
    }
    #faqList >>> code {
        color: black;
    }
</style>